<template>
  <span
    class="NavigationItem"
    :class="classes">

    <span
      v-if="showLabel"
      class="NavigationItem__label">{{ item.name }}
    </span>

    <div class="d-inline icon-add "  v-if="showLabel">
      <vs-button color="dark" type="border" icon="add" size="small" title="Neue Seite anlegen" @click="openNewFileModal"></vs-button>
      <vs-button color="dark" type="border" icon="create_new_folder" size="small" title="Neuer Ordner anlegen"></vs-button>
    </div>

    <router-link
      v-if="showRouterLink && $route.fullPath == item.meta.target"
      :to="item.meta.target"
      class="NavigationItem__router-link is-active-item">{{ item.name }}</router-link>

    <router-link
            v-if="showRouterLink && $route.fullPath != item.meta.target"
            :to="item.meta.target"
            class="NavigationItem__router-link">{{ item.name }}</router-link>

    <a
      v-if="showHyperLink"
      :href="item.meta.target"
      class="NavigationItem__link">{{ item.name }}</a>

    <a
      v-if="showExternalHyperLink"
      :href="item.meta.target"
      target="_blank"
      class="NavigationItem__external-link">{{ item.name }}</a>

      <vs-popup title="Neue Datei erstellen" :active="isNewFileModalOpen" @close="isNewFileModalOpen = false" class="order-cancel-modal">

          <p>Ordnerpfad: <b>{{directoryPath}}</b></p>
          <vs-input v-model="newFileName" label="Dateinamen inkl. Dateiendung" class="w-full mb-2 mt-2"
                    placeholder="Dateinamen" ></vs-input>

        <p>Bitte einen Web-Dateinamen verwenden. Beispiel: about-us.md (inkl. MD Dateiendung)</p>
         <div class="vx-row">
            <div class="vx-col w-full">
              <vs-button @click="onClickSaveNewFile" class="mr-3 mb-2">Speichern</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="isNewFileModalOpen = false">Abbrechnen</vs-button>
            </div>
        </div>
      </vs-popup>
  </span>
</template>

<script>
import ApiService from "../../../../api";

export default {
  data() {
    return {
      active: false,
      isNewFileModalOpen: false,
      isNewDirectoryModalOpen: false,
      newFileName:""
    };
  },
  props: {
    item: Object,
    required: true,
  },
  methods: {
    openNewFileModal() {
      this.isNewFileModalOpen = true;
    },
    isActive() {
      if (this.item.meta.target === '') {
        return false;
      }

      if (this.$route) {
        return (
          (this.$route.path + this.$route.hash).endsWith(
            this.item.meta.target
          ) ||
          (this.$route.path + this.$route.hash).endsWith(
            this.item.meta.target + '/'
          )
        );
      }

      return (
        window.location.href.endsWith(this.item.meta.target) ||
        window.location.href.endsWith(this.item.meta.target + '/')
      );
    },
    onClickSaveNewFile() {

      if(!this.newFileName || !this.directoryPath) {
        return  this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Bitte Dateinamen angeben',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      ApiService.post('docs/' + this.directoryPath, {filename: this.newFileName, content: "Hier folgt Inhalt..."})
              .then((response) => {
                if (response.data.status === 'success') {
                  this.isNewFileModalOpen = false;
                  return this.$vs.notify({
                    title: 'Erfolgreich',
                    text: 'Die Datei wurde erfolgreich erstellt',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                  })
                }

                this.$vs.notify({
                  title: 'Fehlgeschlagen',
                  text: 'Die Datei konnte nicht erstellt werden.',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })

              })
              .catch((error) => {
                this.$vs.notify({
                  title: 'Fehlgeschlagen',
                  text: 'Die Datei konnte nicht erstellt werden.',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
              })
    }
  },
  computed: {
    showLabel() {
      return (
        this.item.path === undefined &&
        this.item.element === undefined &&
        this.item.external === undefined
      );
    },
    showRouterLink() {
      return this.showLink && this.$router !== undefined;
    },
    showHyperLink() {
      return this.showLink && this.$router === undefined;
    },
    showExternalHyperLink() {
      return this.item.external !== undefined;
    },
    showLink() {
      return this.item.path !== undefined || this.item.element !== undefined;
    },
    classes() {
      return {
        'NavigationItem--active': this.active,
      };
    },
    directoryPath() {
      if (this.item && this.item.directory) {
        return this.item.directory.replace("docs/","")
      }
      return
    }
  },
  watch: {
    item() {
      this.active = this.isActive();
    },
    $route() {
      this.active = this.isActive();
    },
  },
  mounted() {
    this.active = this.isActive();

    if (!this.$router) {
      window.addEventListener('hashchange', () => {
        this.active = this.isActive();
      });
    }
  },
};
</script>

<style lang="scss">
@import './NavigationItem.scss';


.icon-add {
  float: right;
  margin-left: 20px;
}

.icon-add button.includeIconOnly.small {
  padding: 5px!important;
  width: 21px!important;
  height: 20px!important;
}

.NavigationItem .icon-add {
  display: none;
}

.NavigationItem:hover .icon-add {
  display: inline;
}
.icon-add button {
  float: left!important;
  margin-left:5px
}
</style>
