<template>
  <div class="accounting-create-invoice pt-4 pb-8 w-full" v-if="order">
    <p class="title">Abrechnungsprofil erstellen</p>

    <vs-row class="mt-4">
      <vs-alert color="primary" :active="!isShowingPage " icon="info" class="mb-4">
        Ein Abrechnungsprofil ist nur möglich, wenn das Angebot <b>akzeptiert oder im Projekt</b> ist. Zudem
        muss der Abrechnungstyp auf <b>"Monatlicher Paymo-Report"</b> gestellt sein.
      </vs-alert>
    </vs-row>

    <vs-row class="mt-4" v-if="order.offer_billing_type">
      <vs-alert color="info" :active="true " icon="info" class="mb-4">
        <b>Hinterlegter Abrechnungstyp: </b>
        <p>{{ order.offer_billing_type.name }}</p>
      </vs-alert>
    </vs-row>

    <vs-row class="mt-4" v-if="order.offer_billing_profil">

      <vs-col vs-w="6" class="pr-4">

        <vs-input label="Paymo Projekt Id's (Semikolongetrennt)"
                  v-model="order.offer_billing_profil.paymo_project_ids" class="w-full"
                  :disabled="!isShowingPage"/>
        <vs-input label="Paymo Kunden Id's (Semikolongetrennt)"
                  v-model="order.offer_billing_profil.paymo_client_ids" class="w-full"
                  :disabled="!isShowingPage"/>
        <small>Du kannst entweder eine oder mehrere Projekt Ids hinterlegen <u>oder/und</u> eine oder mehrere
          Kunden Ids. <br><u><b>Empfehlung:</b></u> Je Angebot gibt es typischerweiße ein Paymo-Projekt.
          Entweder Projekt Id oder Kunden ID </small>


        <vs-row class="mt-4 p-3 background-grey">

          <b>Aufgabenlisten einschließen/ausschließen</b>
          <small class="mb-5">Im PDF-Report sind die Aufgabenlisten zwar enthalten, werden aber bei der
            Abrechnung nicht mit abgerechnet. Für z.B. ein Zusatzangebot während eines
            Großprojektes.</small>
          <vs-col vs-w="11" class="pr-4">
            <vs-input label="Auszuschließende Aufgabenlisten für die Abrechnung"
                      v-model="order.offer_billing_profil.exclude_report_tasklist_ids_for_accounting"
                      :disabled="!isShowingPage"
                      class="w-full "/>
          </vs-col>
          <vs-col vs-w="1" class="mt-5 pr-4">

            <vs-button type="filled" @click="openModalforTasklistExcludeTable()"
                       icon="account_tree" size=""
            ></vs-button>
            <vs-popup class="new-report-modal--popup"
                      title="Bitte markiere die auszuschließenden Aufgabenlisten"
                      :active.sync="modalExcludeTaskListForAccountingActive">

              <div class="ml-2">

                <vs-row class="mt-4" v-for="(project, index) in modalExcludeTaskListForAccountingData">
                  <h4>{{ project[0].project.name }}</h4>
                  <i>Paymo Projekt-ID:{{ index }}</i>

                  <vs-row class="mt-4" v-for="(tasklist, index) in project">
                    <vs-col vs-w="1">
                      <vs-checkbox v-model="selectedModalTasklist"
                                   :vs-value="tasklist.id"></vs-checkbox>
                    </vs-col>
                    <vs-col vs-w="11">
                      {{ tasklist.name }}
                    </vs-col>
                  </vs-row>
                </vs-row>

              </div>

              <div class="flex justify-end mt-6">
                <vs-button color="success" class="ml-2" @click="onClickAddExcludeTasklistToProfile">
                  Markierte als auszuschl. Aufgabenliste übernehmen
                </vs-button>
              </div>
            </vs-popup>

          </vs-col>


          <vs-col vs-w="11" class="pr-4">
            <vs-input label="Ausschließlich folgende Aufgabenlisten abrechnen"
                      v-model="order.offer_billing_profil.include_report_tasklist_ids_for_accounting"
                      :disabled="!isShowingPage"
                      class="w-full "/>
            <small>Ignoriert die auszuschließenden Abrechnungen und rechnet nur die hier markierten
              Aufgabenlisten ab.</small>

          </vs-col>
          <vs-col vs-w="1" class="mt-5 pr-4">

            <vs-button type="filled" @click="openModalforTasklistIncludeTable()"
                       icon="account_tree" size=""
            ></vs-button>
            <vs-popup class="new-report-modal--popup"
                      title="Bitte markiere die abzurechnenden Aufgabenlisten"
                      :active.sync="modalIncludeTaskListForAccountingActive">

              <div class="ml-2">

                <vs-row class="mt-4" v-for="(project, index) in modalExcludeTaskListForAccountingData">
                  <h4>{{ project[0].project.name }}</h4>
                  <i>Paymo Projekt-ID:{{ index }}</i>

                  <vs-row class="mt-4" v-for="(tasklist, index) in project">
                    <vs-col vs-w="1">
                      <vs-checkbox v-model="selectedModalTasklist"
                                   :vs-value="tasklist.id"></vs-checkbox>
                    </vs-col>
                    <vs-col vs-w="11">
                      {{ tasklist.name }}
                    </vs-col>
                  </vs-row>
                </vs-row>

              </div>

              <div class="flex justify-end mt-6">
                <vs-button color="success" class="ml-2" @click="onClickAddIncludeTasklistToProfile">Nur
                  die markierten Aufgabenlisten abrechnen
                </vs-button>
              </div>
            </vs-popup>

          </vs-col>


        </vs-row>
        <vs-alert color="primary"
                  :active="order &&
                          order.offer_billing_profil &&
                          order.offer_billing_profil.include_report_tasklist_ids_for_accounting != null &&
                          order.offer_billing_profil.exclude_report_tasklist_ids_for_accounting != null &&
                          order.offer_billing_profil.include_report_tasklist_ids_for_accounting.length >= 1 &&
                          order.offer_billing_profil.exclude_report_tasklist_ids_for_accounting.length >= 1"
                  icon="info" class="mb-4 mt-2">
          Entweder, oder ... aber nicht beides ausfüllen... macht wenig sinn ;-)
        </vs-alert>


        <vs-textarea label="Überschrift | Variablen {$month}, {$year}, {$start_date}, {$end_Date}"
                     v-model="order.offer_billing_profil.name" :disabled="!isShowingPage" class="w-full mt-8"/>
        <span class="text-danger text-sm"
              v-if="errors.has('order.offer_billing_profil.name')">Dieses Feld ist ein Pflichtfeld</span>

        <vs-input label="E-Mail Empfänger (Semikolongetrennt)" v-model="order.offer_billing_profil.recipients"
                  :disabled="!isShowingPage"
                  class="w-full mt-2"/>
        <span class="text-danger text-sm"
              v-if="errors.has('order.offer_billing_profil.recipients')">Dieses Feld ist ein Pflichtfeld</span>

        <vs-input label="E-Mail Empfänger Anrede (Bsp: Hallo XYZ,)" v-model="order.offer_billing_profil.mailsalutation"
                  :disabled="!isShowingPage"
                  class="w-full mt-2"/>
        <span class="text-danger text-sm"
              v-if="errors.has('order.offer_billing_profil.mailsalutation')">Dieses Feld ist ein Pflichtfeld</span>

        <vs-input label="Standard Stundensatz, wenn kein Stundensatz angegeben ist (Trennzeichen: Punkt 99.99)" v-model="order.offer_billing_profil.default_hour_rate"
                  :disabled="!isShowingPage"
                  class="w-full mt-2"/>
        <span class="text-danger text-sm"
              v-if="errors.has('order.offer_billing_profil.default_hour_rate')">Dieses Feld ist ein Pflichtfeld</span>
      </vs-col>

      <vs-col vs-w="6">

        <vs-input label="Zeiteinträge runden in Schritten von (Minuten)"
                  v-model="order.offer_billing_profil.rounding_step" class="w-full mb-2"
                  :disabled="!isShowingPage"/>
        <span class="text-danger text-sm"
              v-if="errors.has('order.offer_billing_profil.rounding_step')">Dieses Feld ist ein Pflichtfeld</span>

        <label>Reihenfolge und Inhalte des <b>Zeitberichts</b>:</label>
        <mg-select v-model="order.offer_billing_profil.data_order" :options="reportOrderOptions"
                   name="selected_report_order" data-vv-as="Inhalt und Reihenfolge"
                   placeholder="Reihenfolge" track-by="value" label="text" :disabled="!isShowingPage" class="mb-2">
        </mg-select>
        <span class="text-danger text-sm"
              v-if="errors.has('order.offer_billing_profil.data_order')">Dieses Feld ist ein Pflichtfeld</span>

        <label>Reihenfolge und Inhalte <b>Rechnung</b>:</label>
        <mg-select v-model="order.offer_billing_profil.accounting_order" :options="accountingOrderOptions"
                   name="selected_report_order" data-vv-as="Inhalt und Reihenfolge Rechnung"
                   placeholder="Reihenfolge Rechnung" track-by="value" label="text" :disabled="!isShowingPage">
        </mg-select>
        <span class="text-danger text-sm"
              v-if="errors.has('order.offer_billing_profil.accounting_order')">Dieses Feld ist ein Pflichtfeld</span>

        <vs-input label="Auf Benutzer-Ids reduzieren (wenn leer = all, value = paymo userId) - (Semikolongetrennt)"
                  v-model="order.offer_billing_profil.users" class="w-full mb-2"
                  :disabled="!isShowingPage"/>

        <vs-checkbox v-model="order.offer_billing_profil.show_timing_in_description" class="ml-0 mt-4"
                     :vs-value="order.offer_billing_profil.show_timing_in_description"
                     :disabled="!isShowingPage">Zeige Aufgaben-Aufwand (Stunden) in der Rechnungsbeschreibung
        </vs-checkbox>
        <vs-checkbox v-model="order.offer_billing_profil.display_tasks_time_budget" class="ml-0 mt-4"
                     :vs-value="order.offer_billing_profil.display_tasks_time_budget"
                     :disabled="!isShowingPage">Aufgaben Budgetstunden
        </vs-checkbox>
        <vs-checkbox v-model="order.offer_billing_profil.display_tasks_remaining_time_budgets" class="ml-0 mt-4"
                     :vs-value="order.offer_billing_profil.display_tasks_remaining_time_budgets"
                     :disabled="!isShowingPage">Verbleibende Aufgaben Budgetstunden
        </vs-checkbox>
        <vs-checkbox v-model="order.offer_billing_profil.exclude_nonbillable_tasks" class="ml-0 mt-4"
                     :vs-value="order.offer_billing_profil.exclude_nonbillable_tasks"
                     :disabled="!isShowingPage">Keine <b>nicht</b>-abrechenbaren Aufgaben anzeigen
        </vs-checkbox>

      </vs-col>

      <div class="flex justify-end mt-6 mb-6">
        <vs-button color="success" @click="onClickSaveProfil" :disabled="!isShowingPage">Abrechnungsprofil
          speichern
        </vs-button>
      </div>

    </vs-row>

    <vue-bootstrap4-table
        v-if="order"
        :rows="order.offer_billing_report"
        :columns="columns"
        :config="config"
        :actions="actions"
        @openModal="openModal"
        @on-change-query="onChangeQuery"
        @on-change-per-page="onChangePerPage"

        class="mt-4 pb-4 border-bottom-light">

      <template slot="empty-results">
        Keine Paymo-Reports gefunden.
      </template>

      <template slot="file" slot-scope="props">
        <div>
          <a :href="props.row.file_url" target="_blank">Download</a>
        </div>
      </template>

      <template slot="errors" slot-scope="props">
        <div>
          <div v-html="props.row.errors" style="color:red;">
          </div>
          <div v-if="!props.row.errors" style="color:green;">Keine Fehler</div>
        </div>
      </template>

      <template slot="status" slot-scope="props">
        <div class="status">
          <vs-row>
            <feather-icon icon="CheckIcon" class="inline-block mr-2 green" svgClasses="w-4 h-4"/>
            Erstellt
          </vs-row>
          <vs-row :class="{
                     'text-grey':  props.row.has_send_mail == 0
                    }">
            <feather-icon icon="CheckIcon" class="inline-block mr-2 green" svgClasses="w-4 h-4"/>
            An Kunde versendet
            <feather-icon icon="CheckIcon" class="ml-2 hovered-icon" v-if="props.row.has_send_mail == 0"
                          svgClasses="w-4 h-4" @click="CheckReportSendMail(props.row)"/>
            <feather-icon icon="XIcon" class="ml-2 hovered-icon red" v-if="props.row.has_send_mail == 1"
                          svgClasses="w-4 h-4" @click="CheckReportSendMail(props.row)"/>
          </vs-row>
          <vs-row :class="{
                     'text-grey':  props.row.is_accounting == 0
                    }">
            <feather-icon icon="CheckIcon" class="inline-block mr-2 green" svgClasses="w-4 h-4"/>
            Abgerechnet
            <feather-icon icon="CheckIcon" class=" ml-2 hovered-icon" v-if="props.row.is_accounting == 0"
                          svgClasses="w-4 h-4" @click="CheckReportAccounting(props.row)"/>
            <feather-icon icon="XIcon" class=" ml-2 hovered-icon red" v-if="props.row.is_accounting == 1"
                          svgClasses="w-4 h-4" @click="CheckReportAccounting(props.row)"/>
          </vs-row>
        </div>
      </template>

      <template slot="actions" slot-scope="props">
        <div class="flex ">
          <vx-tooltip text="Löschen" position="left" v-if="showDeleteBtn(props.row)">
            <vs-button type="filled" @click="deleteRowItem(props.row.id)"
                       icon="delete" size="small" class="mr-2"
            ></vs-button>
          </vx-tooltip>
          <vx-tooltip text="Zeitbericht per Mail versenden" position="left">
            <vs-button type="border" @click="openSendReportModal(props.row)"
                       icon="mail" size="small" class="mr-2"
            ></vs-button>
          </vx-tooltip>
          <vx-tooltip text="Abrechnung erstellen" position="left" v-if="props.row.is_accounting != true">
            <vs-button type="border" @click="openAccountingReportModal(props.row)"
                       icon="attach_money" size="small"
            ></vs-button>
          </vx-tooltip>
        </div>
      </template>
    </vue-bootstrap4-table>

    <vs-popup class="new-report-modal--popup" title="Neuer Report erstellen" :active.sync="modalActive">

      <div class="ml-2">
        <p class="mb-4"><i>Für den Paymo-Report werden die Einstellungen aus dem Abrechnungsprofil
          verwendet.</i></p>

          <div v-if="isDateInterval">
              <label>Zeitraum:</label>
              <mg-select v-model="selectedDateInterval" :options="dateIntervalOptions" name="selected_date_interval"
                         data-vv-as="Zeitraum für die Abrechnung"
                         placeholder="Zeitraum" track-by="value" label="text">
              </mg-select>
          </div>
          <div v-else>
              <vs-row>
                  <vs-col vs-w="6">
                      <vs-input label="Zeitraum von" v-model="selectedStartDate" type="date" class="w-full"/>
                  </vs-col>
                  <vs-col vs-w="6">
                      <vs-input label="Zeitraum bis" v-model="selectedEndDate" type="date" class="w-full"/>
                  </vs-col>
              </vs-row>
          </div>
          <span class="pseudo-link link" @click="isDateInterval = !isDateInterval">Exakten Zeitraum auswählen</span>
      </div>

      <div class="flex justify-end mt-6">
        <vs-button color="success" class="ml-2" @click="onClickCreateReport"
                   :disabled="!isCreateReportButtonActive">Report erstellen
        </vs-button>
      </div>
    </vs-popup>


    <vs-popup class="send-report-modal--popup" title="Report versenden" :active.sync="modalSendReportActive">

      <div class="ml-2">

        <p class="text-sm mb-2 mt-5">Empfänger</p>
        <ul>
          <li v-for="(mail, index) in modalSendReportRecipients">
            <input :id="mail" :value="mail" name="mail" type="checkbox" v-model="checkedRecipients" />
            <label :for="mail"> <span> {{mail}}</span></label>
          </li>
        </ul>

        <vs-alert color="primary" :active="modalSendReportRecipients.length == 0" icon="info" class="mb-4">
          Keine Empfänger beim Abrechnungsprofil hinterlegt
        </vs-alert>

        <i>T.Resl und F.Belthle erhalten automatisch diese Mail in BCC.</i>


        <vs-input label="Betreff" v-model="mailTopic" class="w-full mt-5"/>

        <p class="text-sm mb-2 mt-5">Nachricht</p>
        <vue-editor :editorToolbar="toolbar" v-model="mailTemplate" class="w-full mb-4"></vue-editor>

        <vs-checkbox v-model="personalEmail" class="mb-4">Als persönliche Mail mit Mitarbeiter Signatur
          versenden
        </vs-checkbox>


      </div>

      <div class="flex justify-end mt-6">
        <vs-button color="success" class="ml-2" @click="onClickSendReport">Zeitbericht versenden
        </vs-button>
      </div>
    </vs-popup>


    <vs-popup class="create-accounting-modal" title="Report abrechnen" :active.sync="modalReportAccountingActive">

      <div class="ml-2">


        <div v-for="(accountingData, index) in modalpreperedAccountingData" class="vs-row mb-5">
          <span class="position" v-if="modalpreperedAccountingData.length >= 2">{{ index + 1 }}</span>
          <div :class="modalpreperedAccountingData.length >= 2 ? 'ml-10':''">
            <vs-textarea label="Artikelname"
                         v-model="accountingData.article_name" class="w-full"/>

            <vue-editor :editorToolbar="toolbar" v-model="accountingData.short_description"
                        class="w-full "></vue-editor>
            <vs-row>
              <vx-tooltip position="bottom">
                <span class="ml-1 commission-link">Aufgabenverteilung</span>
                <template v-slot:text>
                  <vs-row v-for="(user, index) in accountingData.commissions">
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                      {{ user.name }}
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="6">
                      {{ Number(user.time / 60 / 60).toFixed(2) }} Std.
                    </vs-col>
                  </vs-row>
                </template>
              </vx-tooltip>
            </vs-row>
            <div class="vx-row mt-2 mb-4">
              <div class="vx-col lg:w-1/5">
                <vs-input class="w-full" label="Anzahl" v-model="accountingData.amount" type="number" disabled=""
                          step=".25"/>
              </div>
              <div class="vx-col lg:w-1/5">

                <vs-input class="w-full" label="Einzelpreis" v-model="accountingData.unit_price" v-currency="{
                                      currency: 'EUR',
                                      locale: 'de',
                                      autoDecimalMode: false,
                                      decimalLength: 2,
                                      min: 0,
                                    }"/>
              </div>
              <div class="vx-col lg:w-1/5">
                <vs-select label="Einheit" v-model="accountingData.volume_unit" class="w-full">
                  <vs-select-item :key="index" :value="item.value" :text="item.label"
                                  v-for="(item, index) in unitOptions"/>
                </vs-select>
              </div>
              <div class="vx-col lg:w-2/5">
                <vs-select label="Artikelzuordnung" v-model="accountingData.article_id" class="w-full" autocomplete>
                  <vs-select-item :key="index" :value="item.id" :text="item.name"
                                  v-for="(item, index) in articleList"/>
                </vs-select>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="flex justify-end mt-6">
        <vs-button color="success" class="ml-2" @click="onClickSaveReportAccounting"
                   :disabled="modalpreperedAccountingData.length == 0">Zeitbericht abrechnen
        </vs-button>
      </div>
    </vs-popup>


  </div>
</template>

<script>

import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table';
import MgSelect from "../../../../components/mg-select/MgSelect";
import ApiService from "../../../../api";
import moment from "moment";
import QueryHelper from "../../../../mixins/helper/query.helper";
import * as qs from "qs";
import staticOptions from "../../../../mixins/static/options";
import PriceHelper from "../../../../mixins/helper/price.helper";
import {getItemMetadata} from "@/components/vue-tree-navigation/components/TreeNavigation/core";

export default {
  name: "OfferBillingProfile",
  components: {
    VueBootstrap4Table,
    MgSelect
  },
  data: function () {
    return {
      modalReportAccountingActive: false,
      modalAccountingSelectedReport: [],
      modalpreperedAccountingData: [],
      modalpreperedReportId: null,

      modalSendReportActive: false,
      modalSendReportRecipients: [],
      checkedRecipients: [],
      modalSendReportSelectedReport: [],
      personalEmail: true,
      mailTopic: '',
      mailTemplate: '',
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}, {'font': []}],
        [{'color': []}, {'background': []}],
        [{'script': 'sub'}, {'script': 'super'}, {'align': []}],
        ['link'],
      ],

      modalActive: false,

      modalExcludeTaskListForAccountingActive: false,
      modalIncludeTaskListForAccountingActive: false,
      modalExcludeTaskListForAccountingData: [],
      order: null,
      selectedModalTasklist: [],
      reportOrderOptions: [
        {text: 'Projekte > Aufgabenlisten > Aufgaben', value: 1},
        {text: 'Projekte > Aufgabenlisten > Aufgaben > Zeiteinträge', value: 2},
        {text: 'Projekte > Aufgabenlisten', value: 3},
        {text: 'Kunden > Projekte > Aufgabenlisten > Aufgaben', value: 4},
        {text: 'Projekte > Aufgaben', value: 5},
      ],
      accountingOrderOptions: [
        {text: 'Keine Beschreibung ', value: 1},
        {text: 'Aufgabenlisten ', value: 2},
        {text: 'Aufgabenlisten > Aufgaben', value: 3},
        {text: 'Aufgaben', value: 4},
      ],
      isDateInterval: true,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedDateInterval: null,
      dateIntervalOptions: [
        {text: 'Dieser Monat', value: 'this_month'},
        {text: 'Letzer Monat', value: 'last_month'},
        {text: 'Diese Woche', value: 'this_week'},
        {text: 'Letze Woche', value: 'last_week'},
        {text: 'Gesamte Zeit', value: 'all_time'},
      ],
      data: [],
      emptyData: {
        data_order: 1,
        accounting_order: 3,
        show_timing_in_description: 1,
        display_tasks_remaining_time_budgets: 0,
        display_tasks_time_budget: 1,
        exclude_nonbillable_tasks: 1,
        exclude_report_tasklist_ids_for_accounting: null,
        include_report_tasklist_ids_for_accounting: null,
        rounding_step: 10,
        name: "",
        paymo_project_ids: "",
        paymo_client_ids: "",
        recipients: "",
        offer_id: this.$route.params.id,
      },
      actions: [
        {
          btn_text: "Neuen Report erstellen",
          event_name: "openModal",
          color: 'success'
        }
      ],
      columns: [
        {
          label: "Report #",
          name: "id",
          row_text_alignment: 'text-left',
          filter: {
            type: "simple",
            placeholder: "#"
          },
        },
        {
          label: "Name",
          name: "name",
          sort: true,
          row_text_alignment: 'text-left',
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
        },
        {
          label: "Zeitraum von:",
          name: "start_date",
          sort: true,
          row_text_alignment: 'text-left',
          formatter: this.formatDate,
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
        },
        {
          label: "Zeitraum bis:",
          name: "end_date",
          row_text_alignment: 'text-left',
          formatter: this.formatDate,
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
        },
        {
          label: "Fehler",
          name: "errors",
          width: "200px",
          slot_name: "errors",
          row_text_alignment: 'text-left',
        },
        {
          label: "Download",
          name: "file",
          sort: true,
          row_text_alignment: 'text-left',
        },
        {
          label: "Status",
          name: "status",
          slot_name: "status",
          sort: true,
          width: '180px',
          row_text_alignment: 'text-left',
        },
        {
          label: "Erstellt",
          name: "created_at",
          row_text_alignment: 'text-left',
          sort: true,
          formatter: this.formatDateTime,
          initial_sort: true,
          initial_sort_order: 'asc',
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
        },
        {
          label: "Actions",
          slot_name: "actions",
          sort: false,
          showCol: true,
          width: '150px',
        },
      ],
      queryParams: {
        sort: [{
          caseSensitive: true,
          name: "created_at",
          order: "desc"
        }],
        filters: [],
        global_search: "",
        per_page: 25,
        page: 1,
      },
      config: {
        global_search: {
          visibility: false,
        },
        checkbox_rows: false,
        rows_selectable: true,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 25,
      },
      articleList: []
    }
  },
  created() {
    this.fetchData().then(response => {
      this.createInitialProfilData()
    });

  },
  computed: {
    isShowingPage() {
      if (this.order.offer_billing_type_id == 4 && (this.order.order_status.id === 16 || this.order.order_status.id === 19)) {
        return true
      } else {
        return false
      }
    },
    unitOptions() {
      return staticOptions.units;
    },
    isCreateReportButtonActive() {
        if (!this.isShowingPage) {
            return false;
        }

        if (this.isDateInterval) {
            if (!this.selectedDateInterval) {
                return false;
            }
        } else {
            return this.selectedStartDate && this.selectedEndDate && this.selectedEndDate.length > 0 && this.selectedStartDate.length > 0

        }
        return true
    }
  },
  methods: {
    showDeleteBtn(row) {

      if (row.has_send_mail == true || row.is_accounting == true) {
        return false;
      }

      if (moment(new Date(row.created_at)) > moment().subtract(1, 'month')) {
        return true;
      } else {
        return false;
      }
    },
    createInitialProfilData() {
      if (this.order.offer_billing_profil == null) {
        this.order.offer_billing_profil = this.emptyData;
      }
    },
    fetchData() {
      const id = this.$route.params.id;
      let queryParams = QueryHelper.parseRequestParams(this.queryParams);

      this.$vs.loading()
      return ApiService.get(`orders/${id}`, {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.order = response.data.result;
        this.$vs.loading.close()
      }).catch(response => {
      })
    },
    deleteRowItem(id) {
      if (!id) {
        return
      }
      this.$vs.loading();
      ApiService.delete('offer_billing_report/' + id).then((response) => {

        this.fetchData();

        if (response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich gelöscht',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

        this.$vs.loading.close()

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Report konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Report konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    createProfil() {
      this.$vs.loading();

      ApiService.post(`offer_billing_profile`, this.order.offer_billing_profil).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });

          this.fetchData()
        }
      }).catch(response => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
    updateProfil(id) {
      this.$vs.loading();

      ApiService.put(`offer_billing_profile/${id}`, this.order.offer_billing_profil).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.fetchData()
        }
      }).catch(response => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
    onClickSaveProfil() {
      const id = this.order.offer_billing_profil.id;

      if (id) {
        this.updateProfil(id)
      } else {
        this.createProfil()
      }
    },
    formatDateTime(value) {
      return moment(new Date(value)).format('DD.MM.YYYY HH:MM:ss') + " Uhr"
    },
    openModal() {
      this.modalActive = true;
    },

    openSendReportModal(row) {
      this.modalSendReportActive = true;
      this.modalSendReportRecipients = this.order.offer_billing_profil.recipients.split(';');

      this.order.client.contacts.forEach(item => {
        if (this.modalSendReportRecipients.includes(item.email) === false) {
          this.modalSendReportRecipients.push(item.email)
        }
      });

      this.checkedRecipients = this.order.offer_billing_profil.recipients.split(';');
      this.modalSendReportSelectedReport = row

    //  let salutation = this.order.client.invoice_salutation;
      let salutation = "Sehr geehrte Damen und Herren,";
      if(this.order.offer_billing_profil.mailsalutation){
        salutation = this.order.offer_billing_profil.mailsalutation;
      }

      let htmlSie = salutation + "<br>im Anhang finden Sie eine Übersicht der Aufwände im Zeitraum " + this.formatDate(row.start_date) + " - " + this.formatDate(row.end_date) + ".\n\n <br>" +
          "<b>Angebot Nr."+ this.order.invoice_number + " vom "+ this.formatDate(this.order.order_date) +"</b>\n" +
          "<b>Projektname: "  + row.name    + "</b>\n\n<br>" +
          "Wenn Sie keine Fragen hierzu haben wird dies in den nächsten 2 Werktagen in Rechnung gestellt.\n" +
          "\n" +
          "\n" +
          "<div>Bei Fragen zum aktuellen Zeitbericht wenden Sie sich bitte direkt an uns.</div>";

      let htmlDu = salutation + "<br>im Anhang findest du eine Übersicht der Aufwände im Zeitraum " + this.formatDate(row.start_date) + " - " + this.formatDate(row.end_date) + ".\n\n <br>" +
          "<b>Angebot Nr."+ this.order.invoice_number + " vom "+ this.formatDate(this.order.order_date) +"</b>\n" +
          "<b>Projektname: "  + row.name    + "</b>\n\n<br>" +
          "Wenn Du keine Fragen hierzu hast wird dies in den nächsten 2 Werktagen in Rechnung gestellt.\n" +
          "\n" +
          "\n" +
          "<div>Bei Fragen zum aktuellen Zeitbericht wende dich bitte direkt an uns.</div>"


      let html = salutation.includes("Herr") || salutation.includes("Frau") || salutation.includes("geehrte") ? htmlSie : htmlDu;

      this.mailTemplate = html.replace(/\n|\r/g, "");
      this.mailTopic = "Zeitbericht: " + row.name + " ( Angebot #"+ this.order.invoice_number + ")"

    },
    fetchPreperedAccountingData(reportId) {
      this.$vs.loading();
      this.modalpreperedAccountingData = [];
      this.modalpreperedReportId = reportId;

      ApiService.get("offer_billing_report/" + reportId + "/prepered-accounting").then((response) => {
        this.$vs.loading.close();
        this.modalpreperedAccountingData = response.data.result;
        this.$vs.notify({
          title: 'Erfolgreich',
          text: 'Abrechnungsdaten erfolgreich vorbereitet',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })

      }).catch((response) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      });
    },
    fetchArticles() {
      ApiService.get("articles").then((response) => {
        this.articleList = response.data.result;
      }).catch((response) => {
      });
    },
    openAccountingReportModal(row) {
      this.modalReportAccountingActive = true;
      this.modalSendReportSelectedReport = row

      if (this.articleList.length == 0) {
        this.fetchArticles()
      }
      this.fetchPreperedAccountingData(row.id)
    },
    onClickSaveReportAccounting() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$vs.loading();
          let errors = false;

          Object.keys(this.modalpreperedAccountingData).forEach(i => {

              if (parseFloat(this.modalpreperedAccountingData[i].unit_price) < 1){
                  this.$vs.loading.close();
                  this.$vs.notify({
                      title: 'Fehler bei Abrechnung. Einzelpreis muss über 1 sein.',
                      text: 'Einzelpreis zu gering',
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'danger'
                  })
                  errors = true;
                  return;
              }

              this.modalpreperedAccountingData[i].unit_price = PriceHelper.parseCommaFloat(this.modalpreperedAccountingData[i].unit_price);
              // this.modalpreperedAccountingData[i].ek_price = PriceHelper.parseCommaFloat(this.accounting.ek_price);
              this.$vs.loading();
              ApiService.post('accountings', this.modalpreperedAccountingData[i]).then(() => {
                  this.$vs.loading.close();
                  this.$vs.notify({
                      title: 'Erfolgreich',
                      text: 'Die Abrechnung #' + (i + 1) + ' wurde erfolgreich erstellt.',
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'success'
                  });


              }).catch((error) => {
                  errors = true;
                  this.$vs.loading.close();
                  this.$vs.notify({
                      title: 'Fehler bei Abrechnung #' + (i + 1),
                      text: error.message,
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'danger'
                  })
              });

          });

          if(!errors){
              ApiService.put(`offer_billing_report/${this.modalpreperedReportId}`, {"is_accounting": true}).then(response => {
                  if (response.status === 200) {
                      this.fetchData()
                  }
              }).catch(response => {
              });
              this.modalReportAccountingActive = false;
          }
            this.$vs.loading.close();

        }
      });
    },
    openModalforTasklistExcludeTable() {
      this.modalExcludeTaskListForAccountingActive = true;
      if (this.order.offer_billing_profil.exclude_report_tasklist_ids_for_accounting) {
        this.selectedModalTasklist = this.order.offer_billing_profil.exclude_report_tasklist_ids_for_accounting.split(";").map(Number);

      }
      this.$vs.loading();
      this.fetchTasklists().then(response => {
        this.$vs.loading.close();
      })
    },

    openModalforTasklistIncludeTable() {
      this.modalIncludeTaskListForAccountingActive = true;
      if (this.order.offer_billing_profil.include_report_tasklist_ids_for_accounting) {
        this.selectedModalTasklist = this.order.offer_billing_profil.include_report_tasklist_ids_for_accounting.split(";").map(Number);

      }
      this.$vs.loading();
      this.fetchTasklists().then(response => {
        this.$vs.loading.close();
      })
    },
    fetchTasklists() {
      let queryParams = null;

      if (this.order.offer_billing_profil.paymo_project_ids) {
        queryParams = {"project_ids": this.order.offer_billing_profil.paymo_project_ids};
      } else if (this.order.offer_billing_profil.paymo_client_ids) {
        queryParams = {"client_ids": this.order.offer_billing_profil.paymo_client_ids};
      }

      this.$vs.loading()
      return ApiService.get(`offer_billing_profile/paymo-tasklists`, {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {

        if (response.status === 200) {
          this.modalExcludeTaskListForAccountingData = response.data.result;
        } else {
          this.$vs.notify({
            title: 'Fehler',
            text: 'Konnte keine Aufgabenlisten zu Projekt oder Kunden Ids finden.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
          this.modalExcludeTaskListForAccountingActive = false;
          this.modalIncludeTaskListForAccountingActive = false;
        }
        this.$vs.loading.close()
      }).catch(response => {
        this.$vs.notify({
          title: 'Fehler',
          text: 'Konnte keine Aufgabenlisten zu Projekt oder Kunden Ids finden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
        this.modalExcludeTaskListForAccountingActive = false;
        this.modalIncludeTaskListForAccountingActive = false;
        this.$vs.loading.close()
      })
    },
    onClickAddExcludeTasklistToProfile() {
      let str = this.selectedModalTasklist.join(";");
      this.order.offer_billing_profil.exclude_report_tasklist_ids_for_accounting = str;
      this.modalExcludeTaskListForAccountingActive = false;
    },
    onClickAddIncludeTasklistToProfile() {
      let str = this.selectedModalTasklist.join(";");
      this.order.offer_billing_profil.include_report_tasklist_ids_for_accounting = str;
      this.modalIncludeTaskListForAccountingActive = false;
    },
    formatDate(value) {
      if (!value) {
        return null;
      }
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },
    onChangePerPage(value) {
      this.config.per_page = value;
      this.fetchData();
    },
    onClickSendReport(row) {
      this.$vs.loading()
      let data = {
        "offer_id": this.$route.params.id,
        "offer_report_id": this.modalSendReportSelectedReport.id,
        "personal": this.personalEmail,
        "content": this.mailTemplate,
        "subject": this.mailTopic,
        "emails": this.checkedRecipients.join(';'),
        "cc": null,
        "bcc": null,
        "include_signature": false,
        "mail_template_name":'offer_billing_report',
        //Todo!
        "from":"info@mediagraphik.de"
      };

      ApiService.post('offer_billing_report/send-mail', data).then((response) => {
        this.$vs.notify({
          title: 'Erfolgreich',
          text: 'Erfolgreich versendet',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
        this.modalSendReportActive = false;
        this.CheckReportSendMail(this.modalSendReportSelectedReport)
        this.fetchData().then((response) => {
          this.$vs.loading.close();
        });

      }).catch((response) => {

        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      });
    },
    onClickCreateReport() {
      this.$vs.loading()
        let data;
        if (this.isDateInterval) {
             data = {
                "offer_id": this.$route.params.id,
                "date_interval": this.selectedDateInterval
            };
        } else {
             data = {
                "offer_id": this.$route.params.id,
                "start_date": this.selectedStartDate,
                "end_date": this.selectedEndDate
            };
        }


      ApiService.post('offer_billing_report', data).then((response) => {
        this.$vs.notify({
          title: 'Erfolgreich',
          text: 'Erfolgreich angelegt',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
        this.modalActive = false;
        this.fetchData().then((response) => {
          this.$vs.loading.close();
        });

      }).catch((response) => {

        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      });
    },
    CheckReportSendMail(row) {
      ApiService.put(`offer_billing_report/${row.id}`, {"has_send_mail": !row.has_send_mail}).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.fetchData()
        }
      }).catch(response => {
        this.$vs.loading.close();
      });
    },
    CheckReportAccounting(row) {
      ApiService.put(`offer_billing_report/${row.id}`, {"is_accounting": !row.is_accounting}).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.fetchData()
        }
      }).catch(response => {
        this.$vs.loading.close();
      });
    },
    parseAmount(amount) {
      return amount.toFixed(2);
    },
    formatDate(value) {
      return moment(value).format('DD.MM.YYYY');
    },

  },
  filters: {
    date(value) {
      return moment(value).format('DD.MM.YYYY');
    },
    dateTime(value) {
      return moment(value).format('DD.MM.YYYY HH:mm') + ' Uhr';
    }
  }
}
</script>

<style lang="scss">
.accounting-create-invoice {
  .title {
    font-weight: bold;
    font-size: 15px;
  }

  .summary {
    background-color: #f8f8f8;
    padding: 15px;
    border: 1px solid #dccbcb;
    border-radius: 3px;
  }

  .border-bottom-light {
    border-bottom: 1px solid #dccbcb;
  }

  .vs-button {
    margin: 0 auto;
  }

  .orange {
    color: #ec7b24;
  }

  .amount {
    font-size: 16px;
    font-weight: bold;
  }
}

.vs-checkbox--input {
  z-index: 10;
}

.background-grey {
  background: #ebebeb;
}

.status {
  color: #23a000;

  .vs-row {
    .hovered-icon {
      display: none;
    }

    &:hover {
      .hovered-icon {
        display: inline-block;
        color: green;
        cursor: pointer;
      }

      .hovered-icon.red {

        color: red;
      }
    }
  }

}

.create-accounting-modal .vs-popup {
  width: 900px;

  .vs-popup--content {
    overflow-y: auto;
    overflow-x: visible;
  }

  span.position {
    float: left;
    background: #dbdbdb;
    width: 31px;
    position: relative;
    display: block;
    text-align: center;
    height: 31px;
    vertical-align: middle;
    padding-top: 6px;
    border-radius: 20px;
    font-weight: 600;
    color: #b10e0e;
  }
}

.vs-tooltip {
  z-index: 99999999;
  width: 100% !important;
  max-width: 275px;
}

.commission-link {
  color: #b10e0e;
  font-size: 10px;
  cursor: pointer;

  position: relative;
}
</style>
